import Link from "next/link";

import EachLink from "./EachLink";
import { motion } from "framer-motion";
// types
import { LinkType } from "types/allTypes"; 
import MobileMenu from "./MobileMenu";
import { LogoSVG } from "assets/svg"; 
import { useRouter } from "next/router";
import { logoFont } from "assets/font";

const Header: React.FC = () => {
  const router = useRouter();
  const headerLinks: LinkType[] = [
    {
      ref: "/",
      text: "Home",
    },
    {
      ref: "/#projects",
      text: "Our work",
    },
    {
      ref: "/#team",
      text: "Team",
    },
    {
      ref: "/#contact-us",
      text: "Contact us",
    },
  ];
  const clickHandler = () =>{
    router.push("/", undefined, {scroll:false,shallow:true});
  }

  return (
    <div className="w-full h-28 z-[10000] pointer-events-none">
      <div className="flex justify-start items-center w-full h-full relative">
        <span className="absolute top-10 left-[32px] lg:left-32 z-100 pointer-events-auto cursor-pointer"
        onClick={clickHandler}
        >
            <div className="flex justify-start items-center fill-white hover:scale-110 duration-1000">
              <LogoSVG width="4rem" />
              <span className={`text-[32px] text-primary-50 font-bold  ${logoFont.className}`}>
                Spila
              </span>
            </div>
        </span>
        <motion.ul className="absolute pointer-events-auto top-14 right-32 hidden lg:flex justify-start items-center gap-x-5 lg:gap-x-8 z-110">
          {headerLinks.map((headLink) => {
            return <EachLink key={headLink.ref} headLink={headLink} />;
          })}
        </motion.ul>
        <MobileMenu headerLinks={headerLinks} />
      </div>
    </div>
  );
};

export default Header;
