import { Variants } from "framer-motion";
import { delay } from "lodash";

export const socialAni: Variants = {
  hidden: {
    scale: 0,

    transition: {
      duration: 0.5,
      ease: [0.95, 0.32, 0.37, 1.21],
    },
  },
  visible: {
    scale: 1,

    transition: {
      duration: 0.5,
      ease: [0.95, 0.32, 0.37, 1.21],
    },
  },
};

export const mobileMenuContainerAni: Variants = {
  opened: {
    height: "100vh",
    transition: {
      when: "beforeChildren",
      duration: 0.01,
    },
  },
  closed: {
    height: "100%",
    transition: {
      when: "afterChildren",
      duration: 0.2,
    },
  },
};

export const mobileMenuAni: Variants = {
  opened: (custom = 1000) => ({
    clipPath: `circle(${custom * 2 + 200}px at calc(100% - 46px) 51px)`,
    backgroundColor: "#F4EEFE",
    transition: {
      clipPath: { type: "spring", stiffness: 20, restDelta: 2 },
    },
  }),
  closed: {
    clipPath: "circle(20px at calc(100% - 58px) 72px)",
    backgroundColor: "#F4EEFE",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

export const navBodyAni: Variants = {
  opened: {
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

export const menuItemAni: Variants = {
  opened: {
    display: "flex",
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transitionEnd: {
      display: "none",
    },
    transition: {
      y: { stiffness: 1000 },
    },
  },
};
export const menuSocialItemAni: Variants = {
  opened: {
    display: "flex",
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    x: 100,
    opacity: 0,
    transitionEnd: {
      display: "none",
    },
    transition: {
      x: { stiffness: 1000 },
    },
  },
};
export const menuCopyRightAni: Variants = {
  opened: {
    display: "flex",
    x: 0,
    opacity: 1,
    transition: {
      opacity: { delay: 0.5 },
      x: { delay: 0.5, stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    x: 50,
    opacity: 0,
    transitionEnd: {
      display: "none",
    },
    transition: {
      x: { stiffness: 1000 },
    },
  },
};

export const projectAnim: Variants = {
  hidden: {
    opacity: 0,
    scale: 0.95,
  },
  visible: (custom) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay:0.1 * (custom+1)
    },
  }),
};


export const teamAnim: Variants = {
  hidden: {
    opacity: 0,
    scale: 0.95,
  },
  visible: (custom) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay:0.1 * (custom+1)
    },
  }),
};

export const modalAni: Variants = {
  hidden: {
    backgroundColor:"rgb(120,118,122,0)",
    backdropFilter: "blur(0px)",
  },
  visible: {
    backgroundColor:"rgb(120,118,122,0.5)",
    backdropFilter: "blur(10px)",
  },
  out: {
    backgroundColor:"rgb(120,118,122,0)",
    backdropFilter: "blur(0px)",
  },
};

export const benefitSvgAni:Variants = {
  hidden:{
    rotate:"0deg",
    scale:1,
  },
  shake:{
    rotate:["0deg","10deg","5deg","15deg","-5deg","0deg"],
    scale:[1,0.95,0.95,0.95,0.95,1],
    transition:{
      duration:1.5,
      times:[0,0.15,0.2,0.3,0.5,1],
    }
  }
}