//social
import DiscordSVG from "./svgs/discord.svg";
import YoutubeSVG from "./svgs/youtube.svg";
import Twitter from "./svgs/twitter.svg";
import Github from "./svgs/github.svg";
import Instagram from "./svgs/instagram.svg";
import Linkedin from "./svgs/linkedin.svg";
import GitBookSVG from "./svgs/gitbook.svg";
import Telegram from "./svgs/telegram.svg";
import Dribbble from "./svgs/dribbble.svg";
import Codepen from "./svgs/codepen.svg";
import Website from "./svgs/website.svg";
import Whatsapp from "./svgs/whatsapp.svg";
export const socialSVGs = {
  DiscordSVG,
  YoutubeSVG,
  Twitter,
  Github,
  Instagram,
  Linkedin,
  GitBookSVG,
  Telegram,
  Dribbble,
  Codepen,
  Website,
  Whatsapp
};

//logo
import logo from "./svgs/logo.svg";
export const LogoSVG = logo;

//others
import BackToTop from "./svgs/backtotop.svg";
export const BackToTopSVG = BackToTop;

import AngleRight from "./svgs/rightangle.svg";
export const AngleRightSVG = AngleRight;

import ETH from "./svgs/eth.svg";
export const ETHSVG = ETH;

import Ethereum from "./svgs/ethereum.svg";
export const EthereumSVG = Ethereum;

import Plus from "./svgs/plus.svg";
export const PlusSVG = Plus;

import ArrowRight from "./svgs/arrowright.svg";
export const ArrowRightSVG = ArrowRight;

import Reload from "./svgs/reload.svg";
export const ReloadSVG = Reload;

import Hero from "./svgs/hero.svg";
export const HeroSVG = Hero;

import Contact from "./svgs/contact.svg";
export const ContactSVG = Contact;

import Close from "./svgs/close.svg";
export const CloseSVG = Close;

import Sms from './svgs/sms.svg';
export const SmsSVG = Sms;

import ArrowUpRight from './svgs/arrow-up-right.svg';
export const ArrowUpRightSVG = ArrowUpRight;
import Closecircle from './svgs/closecircle.svg';
export const ClosecircleSVG = Closecircle;

import SupportEmail from './svgs/Support@Spila.dev.svg';
export const SupportEmailSVG = SupportEmail;

import Clock from "./svgs/clock.svg";
import Puzzle from "./svgs/puzzle.svg";
import Support from "./svgs/support.svg";
import Update from "./svgs/update.svg";
import Done from "./svgs/done.svg";

export const benefitsSVG = {
  Clock,
  Puzzle,
  Support,
  Update,
  Done
};

import CuteTelegram from "./svgs/cute-telegram.svg";
import CuteWhatsapp from "./svgs/cute-whatsapp.svg";
import CuteGithub from "./svgs/cute-github.svg";
import CuteGmail from "./svgs/cute-gmail.svg";
import CuteLinkedin from "./svgs/cute-linkedin.svg";
import CuteInstagram from "./svgs/cute-instagram.svg";
import CuteDiscord from "./svgs/cute-discord.svg";

export const cuteSocialSVG = {
  CuteTelegram,
  CuteDiscord,
  CuteGithub,
  CuteGmail,
  CuteInstagram,
  CuteLinkedin,
  CuteWhatsapp,
};
