import { useEffect } from "react";

export const useModalScrollbar = (isOpen: Boolean) => {
  useEffect(() => {
    if (!isOpen) return;
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.marginRight = scrollbarWidth + "px";
    document.documentElement.style.overflow = "hidden";

    return () => {
      document.documentElement.style.overflow = "visible";
      document.documentElement.style.marginRight = "0";
    };
  }, [isOpen]);
};
