import Link from "next/link";
import { useRouter } from "next/router";
import { motion } from "framer-motion";
import { LinkType } from "types/allTypes";



interface props {
  headLink: LinkType;
}
const EachLink: React.FC<props> = ({ headLink,  }) => {
  const router = useRouter();


  return (
    <motion.li
    className={`text-large-medium text-black/50 hover:text-black/70 duration-300 ${
      router.pathname === headLink.ref
        && "!text-black"
    }`}
      key={headLink.text}
    >
      <Link href={headLink.ref}>
          {headLink.text}
      </Link>
    </motion.li>
  );
};

export default EachLink;
