import Social from "components/common/Social";
import { motion } from "framer-motion";
import Image from "next/image";

const Footer: React.FC = () => {
  return (
    <>
      <motion.footer
        layout
        className="h-full w-full flex flex-col justify-center items-center z-100 relative"
      >
        {/* <h3 className="mb-3 text-[1.25rem] ssm:text-[1.75rem] sm:text-[2.375rem] font-bold sm:font-bold z-10">
          Make Your Idea True By
          <span className="text-primary-50"> SPILA</span>
        </h3>
        <span className="w-[1.875rem] h-[.375rem] mb-6 bg-primary-50 z-10 " />
        <ul className="flex justify-center items-center gap-x-1 sm:gap-x-3 mb-36 z-10">
          {[].map((data) => {
            return <Social key={data} data={data} width="3.375rem" svgWidth="1.625rem" hasTransition={true} />;
          })}
        </ul> */}
        <p className="mb-10 text-xs  z-10">
          &copy; COPYRIGHT 2023 Spila. ALL RIGHTS RESERVED
        </p>
      </motion.footer>
    </>
  );
};

export default Footer;
