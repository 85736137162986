import { socialType } from "../../../types/allTypes";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { socialAni } from "../../../utils/animation";
interface props {
  data: socialType;
  svgWidth: string;
  width: string;
  hasTransition: boolean;
  isSquare?: boolean;
}
const Social: React.FC<props> = ({
  data,
  svgWidth,
  width,
  hasTransition,
  isSquare,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <motion.li
      onHoverStart={() => setIsHovering(true)}
      onHoverEnd={() => setIsHovering(false)}
      onTouchStart={() => setIsHovering(true)}
      onTouchEnd={() => setIsHovering(false)}
      className={`group/social text-xl  bg-neutral-700 flex justify-center items-center 
        aspect-square will-change-transform ${
          hasTransition && "duration-500 delay-200"
        } ${isSquare ? "rounded-none bg-[rgba(126,92,255,0.09)]" : "rounded-full"}`}
      style={{ width }}
    >
      <a
        className="w-full h-full group-hover/social:text-blackPrime flex justify-center items-center relative will-change-transform"
        href={data.href}
        target="_blank"
      >
        <AnimatePresence>
          {isHovering && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={socialAni}
              style={{ width }}
              className={`absolute left-0 top-0 z-0 bg-white aspect-square will-change-transform ${isSquare ?"":"rounded-full"}`}
            ></motion.div>
          )}
        </AnimatePresence>
        <span
          className={`z-10 will-change-transform fill-[rgba(24,23,27,1)] group-hover:fill-white ${
            isHovering && "!fill-primary-50"
          } ${hasTransition && "duration-300 delay-75"}`}
          style={{ width: svgWidth }}
        >
          {data.icon}
        </span>
      </a>
    </motion.li>
  );
};

export default Social;
