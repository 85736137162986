import { StaticImageData } from "next/image";

// projects
import demmortaltreasure from "./images/project/demmortaltreasure.png";
import blocklogica from "./images/project/blocklogica.png";
import wikianime from "./images/project/wikianime.png";
import musicWorld from "./images/project/music-box.png";
import ithil from "./images/project/ithil.png";
import todoTopia from "./images/project/todo-topia.png";
import nftation from "./images/project/nftation.png";
import wizardex from "./images/project/wizardex.png";
import blocklogicaLogo from "./images/project/blocklogica-logo.png";

export const projectsImgs = {
  demmortaltreasure,
  wikianime,
  musicWorld,
  ithil,
  todoTopia,
  blocklogica,
  nftation,
  wizardex,
  blocklogicaLogo,
};

// team
import alyMohamadi from "./images/team/aly-mohamadi.png";
import arianHamdi from "./images/team/arian-hamdi.png";
// import hamidrezaMoradi from "./images/team/hamidreza-moradi.jpg";
// import defiMancer from "./images/team/defi-mancer.jpg";
// import mehdiAbdie from "./images/team/mehdi-abdie.jpg";
import mohrez from "./images/team/mohammadreza-alizadeh.png";
import soheilVatanpoor from "./images/team/soheil-vatanpoor.png";
import sadeghGhafari from "./images/team/sadegh-ghafari.jpg";

export const teamImgs = {
  alyMohamadi,
  arianHamdi,
  // hamidrezaMoradi,
  // defiMancer,
  // mehdiAbdie,
  mohrez,
  soheilVatanpoor,
  sadeghGhafari,
};

import heroImg from "./images/hero.png";

import bgImg1 from "./images/bg1.png";

import bgImg2 from "./images/bg2.png";

export { heroImg, bgImg1, bgImg2 };
