import Footer from "./Footer";
import Header from "./Header";
import Main from "./Main";
import { usePathCondition } from "hooks/usePathCondition";
import { bodyFont, headingFont } from "assets/font";
import { ToastContainer } from "react-toastify";

interface props {
	children: React.ReactNode;
}

const Layout: React.FC<props> = ({ children }) => {
	const layoutHeaderException = usePathCondition("1");
	const layoutFooterException = usePathCondition(["1", "jkjk"]);

	return (
		<div
			className={`w-full h-full flex flex-col justify-between items-start overflow-hidden relative ${bodyFont.variable} ${headingFont.variable} font-body`}
		>
			{!layoutHeaderException && <Header />}
			<Main>{children}</Main>
			{!layoutFooterException && <Footer />}
		</div>
	);
};

export default Layout;
