import { projectsImgs, teamImgs } from "assets/img";
import { benefitsSVG, cuteSocialSVG, socialSVGs } from "assets/svg";
import {
  benefitType,
  commentType,
  contactType,
  metaDataType,
  projectType,
  socialType,
  statusListType,
  teamMemberType,
} from "types/allTypes";
import { contactLink } from "./constants";

export const footerData: socialType[] = [
  {
    icon: <cuteSocialSVG.CuteGithub />,
    href: contactLink.github,
  },
  {
    icon: <cuteSocialSVG.CuteTelegram />,
    href: contactLink.telegram,
  },
  {
    icon: <cuteSocialSVG.CuteGmail />,
    href: contactLink.gmail,
  },
  {
    icon: <cuteSocialSVG.CuteLinkedin />,
    href: contactLink.linkedin,
  },
  {
    icon: <cuteSocialSVG.CuteWhatsapp />,
    href: contactLink.whatsapp,
  },
];

export const contactList: contactType[] = [
  {
    name: "Telegram",
    link: contactLink.telegram,
    icon: <cuteSocialSVG.CuteTelegram />,
  },
  {
    name: "Whatsapp",
    link: contactLink.whatsapp,
    icon: <cuteSocialSVG.CuteWhatsapp />,
  },
  {
    name: "Linkedin",
    link: contactLink.linkedin,
    icon: <cuteSocialSVG.CuteLinkedin />,
  },
  {
    name: "Gmail",
    link: contactLink.gmail,
    icon: <cuteSocialSVG.CuteGmail />,
  },
  {
    name: "Github",
    link: contactLink.github,
    icon: <cuteSocialSVG.CuteGithub />,
  },
];

export const techs = {
  react: { name: "ReactJs", color: "#00ffff" },
  next: { name: "NextJs", color: "#000000" },
  sass: { name: "Sass", color: "#ff80c0" },
  tailwindcss: { name: "TailwindCss", color: "#00dadf" },
  typescript: { name: "TypeScript", color: "#0080ff" },
  javascript: { name: "JavaScript", color: "#f2f200" },
  html: { name: "HTML", color: "#ff0000" },
  css: { name: "CSS", color: "#0080ff" },
  redux: { name: "Redux", color: "#8000ff" },
  zustand: { name: "Zustand", color: "#d9e311" },
  reactquery: { name: "React Query", color: "#ff0000" },
  apolloclient: { name: "ApolloClient", color: "#000000" },
  git: { name: "Git", color: "#ff8000" },
  framermotion: { name: "Framer Motion", color: "#ff0080" },
  graphql: { name: "GraphQL", color: "#ff0080" },
  restapi: { name: "RestAPI", color: "#0000ff" },
  figma: { name: "Figma", color: "#000000" },
  wagmihook: { name: "WAGMI hook", color: "#000000" },
  ethers: { name: "EthersJs", color: "#000080" },
  viem: { name: "Viem", color: "#EBC84E" },
  web3: { name: "Web3Js", color: "#ff925e" },
  mui: { name: "Material UI", color: "#000ad1" },
  chakraui: { name: "Chakra UI", color: "#000ad1" },
  photoshop: { name: "Photoshop", color: "#2FA3F7" },
  illustrator: { name: "Adobe Illustrator", color: "#FF9A00" },
  tinaCMS: { name: "TinaCMS", color: "#FF9A00" },
  python: { name: "Python", color: "#EBC84E" },
  django: { name: "Django", color: "#092D1F" },
  psql: { name: "PSQL", color: "#31648C" },
  swagger: { name: "Swagger", color: "#6A9500" },
  solidity: { name: "Solidity", color: "#363636" },
  ethereumNetwork: { name: "Ethereum network", color: "#363636" },
  theGraph: { name: "theGraph", color: "#4D44BB" },
  hardHat: { name: "hardHat", color: "#E8CE0C" },
  slither: { name: "Slither", color: "#A26F46" },
  mythx: { name: "mythx", color: "#3284C8" },
  ipfs: { name: "IPFS", color: "#60CCD0" },
  tma: { name: "Telegram Mini App", color: "#0088cc" },
};
export const projects = {
  all: { name: "All", color: "#750D37" },
  uiux: { name: "UI/UX design", color: "#008040" },
  frontend: { name: "Front-End", color: "#400080" },
  backend: { name: "Back-End", color: "#007d7d" },
  logo: { name: "Logo design", color: "#76AB36" },
  blockchain: { name: "Blockchain", color: "#3284C8" },
  brandDesign: { name: "Brand", color: "#76AB36" },
};

export const roles = {
  all: { name: "All", color: "#750D37" },
  develop: { name: "Development", color: "#6400c8" },
  design: { name: "Design", color: "#f04e00" },
  product: { name: "Product", color: "#4f4fff" },
};

export const benefitData: benefitType[] = [
  {
    icon: <benefitsSVG.Clock />,
    title: "On-time delivery",
    desc: "With a strong emphasis on timing, Spila helps you plan your schedule",
    link: "#",
  },
  {
    icon: <benefitsSVG.Puzzle />,
    title: "Customized Creativity",
    desc: "Tailored innovative design and functionality that set your brand apart",
  },
  {
    icon: <benefitsSVG.Done />,
    title: "Cost-conscious development",
    desc: "Leveraging efficient strategies to deliver affordable yet quality-driven solutions",
  },
  {
    icon: <benefitsSVG.Update />,
    title: "Beyond an external contractor",
    desc: "Integrated team with end-to-end support from ideation to long-term maintenance",
  },
  // { icon: <Twitter />, desc: "99.99% bug-free codebase" },
];

export const projectsTabData = [
  {
    name: "finished",
  },
  {
    name: "in progress",
  },
];

export const projStatus: statusListType = {
  finished: { code: 0, color: "#036D2D", name: "Finished" },
  inProgress: { code: 1, color: "#563BFF", name: "In Progress" },
};

export const projectsData: projectType[] = [
  {
    id: "0",
    name: "Demmortal treasure",
    desc: "Demmortal Treasure, a groundbreaking web3 Next.js application, is redefining decentralized economics on the Ethereum blockchain. Users can seamlessly mint NFTs and engage in a unique lottery experience, complete with captivating animations on the game board and a sophisticated NFT auction featuring price drops every 10 minutes. With a focus on precision in UI implementation, our completed auction and game board sections await release. On the backend, we utilize Python, Django, and DRF for rapid API development, complemented by PSQL for efficient database access, and a dedicated blockchain indexer to enhance data currency. Rigorous testing and documentation ensure the accuracy of the lottery algorithm, while Solidity and the Ethereum network power blockchain functionalities, optimized for reduced gas fees. The development process is streamlined with CICD infrastructure, security analysis tools, and testing on private blockchains before public deployment, ensuring a secure and efficient final product.",
    link: "https://demmortaltreasure.vercel.app/",
    image: projectsImgs.demmortaltreasure,
    status: projStatus.finished,
    type: [projects.frontend, projects.backend, projects.blockchain],
    techDesc:
      "Demmortal Treasure employs cutting-edge technologies, utilizing React/Next for the front end, Python, Django, and DRF for efficient backend development, and ethers for seamless blockchain interactions. The implementation also includes TypeScript for enhanced type safety, ensuring a robust and bug-resistant system.",
    techs: [
      techs.react,
      techs.next,
      techs.tailwindcss,
      techs.typescript,
      techs.ethers,
      techs.framermotion,
      techs.reactquery,
      techs.wagmihook,
      techs.zustand,
      techs.python,
      techs.django,
      techs.swagger,
      techs.psql,
      techs.solidity,
      techs.ethereumNetwork,
      techs.theGraph,
      techs.hardHat,
      techs.slither,
      techs.mythx,
      techs.ipfs,
    ],
    shortDesc: "Decentralized finance",
    hashtags: [projects.blockchain, projects.frontend],
  },
  {
    id: "1",
    name: "Blocklogica",
    desc: "One of our successful collaborations is with Blocklogica agency, based in Italy. Our mission was designing and developing a landing page with the latest trends in the blockchain industry. In the development process, our main focus was on creating creative animations, such as scrolling pagination.",
    link: "https://blocklogica.io",
    image: projectsImgs.blocklogica,
    status: projStatus.finished,
    type: [projects.frontend, projects.uiux],
    techDesc:
      "In this project, we're leveraging cutting-edge technologies like Next.js, Framer Motion, and Tailwind CSS for a superior user experience. TypeScript ensures smooth, error-free coding, and TinaCMS makes content management a breeze. It's the dream team for an amazing project.",
    techs: [
      techs.react,
      techs.next,
      techs.tailwindcss,
      techs.framermotion,
      techs.tinaCMS,
      techs.git,
      techs.html,
      techs.css,
      techs.typescript,
    ],
    shortDesc: "Blockchain agency",
    hashtags: [projects.frontend, projects.uiux],
  },
  {
    id: "145678",
    name: "Wizardex",
    desc: "Wizardex redefines decentralized exchanges by introducing a seamless user experience and conducting all operations on the frontend and through smart contracts. With innovative features like zero fees, a fully on-chain order book, an anti-MEV order priority system, a sleek and minimal interface, and take-profit order types, WizarDex is poised to revolutionize on-chain token trading.",
    link: "https://wizardex-test.vercel.app/",
    image: projectsImgs.wizardex,
    status: projStatus.finished,
    type: [projects.frontend, projects.uiux],
    techDesc:
      "In this project, we utilize technologies such as React, Next.js, Material-UI, Sass, TypeScript, WagmiHook, Ethers, React Query, Git, HTML, and CSS to enhance the user experience. These tools work together seamlessly for efficient coding and straightforward content management with TinaCMS, contributing to a well-rounded project.",
    techs: [
      techs.react,
      techs.next,
      techs.mui,
      techs.sass,
      techs.typescript,
      techs.wagmihook,
      techs.ethers,
      techs.reactquery,
      techs.git,
      techs.html,
      techs.css,
    ],
    shortDesc: "Decentralized exchange",
    hashtags: [projects.frontend, projects.uiux],
  },
  {
    id: "25",
    name: "NFTation",
    desc: "NFTation is the premier NFT marketplace, dedicated to connecting NFT collectors with digital artists through a user-friendly platform. Our mission is to design an intuitive space for users to effortlessly explore, collect, and trade NFTs. Join us on this journey where passion meets technology, and experience the seamless world of NFT exploration. NFTation: Where art and innovation converge.",
    link: "https://www.nftation.io/",
    image: projectsImgs.nftation,
    status: projStatus.finished,
    type: [projects.uiux],
    techDesc: "",
    techs: [techs.figma, techs.illustrator],
    shortDesc: "NFT marketplace",
    hashtags: [projects.uiux],
  },
  {
    id: "2",
    name: "WikiAnime",
    desc: "Built as a React single-page application, this project relies on pure HTML, CSS, and JavaScript, eschewing any UI library. Navigating through an extensive collection of anime, manga, movies, and more is made seamless, with data management handled by GraphQL and ApolloClient. Thanks to an advanced caching mechanism, users can enjoy swift loading times and smooth navigation.",
    link: "https://wikianime.aly3n.dev",
    image: projectsImgs.wikianime,
    status: projStatus.finished,
    type: [projects.frontend],
    techDesc:
      "In this project we tried to use modern Technologies. The back-end is apollo server and data in front-end implemented by apollo client.",
    techs: [
      techs.html,
      techs.css,
      techs.javascript,
      techs.react,
      techs.tailwindcss,
      techs.framermotion,
      techs.git,
      techs.graphql,
      techs.apolloclient,
    ],
    shortDesc: "Anime list",
    hashtags: [projects.frontend],
  },
  {
    id: "7",
    name: "Music World",
    desc: 'The Music World website is an online music player. In the front end of the project, React, Sass, context API, and React Query has been used, however, we have used a ready API "Deezer" for the back end.',
    link: "https://music2world.netlify.com/",
    image: projectsImgs.musicWorld,
    status: projStatus.finished,
    type: [projects.frontend],
    techDesc:
      'React, React-Query, Sass, however, I have used a ready api "deezer" for the back-end.',
    techs: [
      techs.html,
      techs.css,
      techs.react,
      techs.framermotion,
      techs.git,
      techs.reactquery,
    ],
    shortDesc: "Music Player Hub",
    hashtags: [projects.blockchain, techs.figma],
  },
  {
    id: "20",
    name: "ITHIL",
    desc: "Ithil serves as a financial interoperability layer, connecting the web3 space for crowdlending and new value creation. Developed using React, Next.js, TypeScript, ChakraUI, Tailwind CSS, Wagmi, and Viem, the platform enables borrowers to access web3 financial services, while lenders earn fees, and liquidators safeguard positions. Users benefit from single-sided APY, one-click investment strategies, on-chain asset purchases, and the option to rent NFTs for exclusive community access or Play2Earn games, marking Ithil as a versatile and innovative intersection of finance and web3.",
    link: "https://app.ithil.fi",
    image: projectsImgs.ithil,
    status: projStatus.finished,
    type: [projects.uiux, projects.backend, projects.blockchain, projects.uiux],
    techDesc:
      "Ithil, built with React, Next.js, TypeScript, ChakraUI, Tailwind CSS, Wagmi, and Viem, offers a seamless financial interoperability layer for web3. TypeScript enhances reliability, ensuring a robust platform for crowdlending and value creation.",
    techs: [
      techs.react,
      techs.next,
      techs.chakraui,
      techs.tailwindcss,
      techs.wagmihook,
      techs.hardHat,
      techs.ipfs,
      techs.typescript,
      techs.viem,
      techs.ethers,
      techs.web3,
      techs.zustand,
      techs.figma,
    ],
    shortDesc: "Financial Web3 Integration",
    hashtags: [projects.blockchain, projects.frontend],
  },
  {
    id: "21",
    name: "Todo Topia",
    desc: "Todo Topia is an intuitive todo list application designed to help users organize and categorize their tasks efficiently. With this tool, users can effortlessly segregate tasks, ensuring that their daily chores, professional commitments, and personal goals are neatly delineated and easily accessible.",
    link: "https://t.me/todo_topia_bot",
    image: projectsImgs.todoTopia,
    status: projStatus.finished,
    type: [projects.backend,projects.frontend],
    techDesc:
      "In this project we tried to use modern and updated technologies.",
    techs: [
      techs.react,
      techs.next,
      techs.sass,
      techs.typescript,
      techs.tma,
      techs.reactquery,
      techs.framermotion,
    ],
    shortDesc: "Telegram todo list",
    hashtags: [projects.frontend],
  },
  {
    id: "21783560236",
    name: "Blocklogica Logo",
    desc: "One of our successful collaborations is with Blocklogica agency, based in Italy. Our mission was designing and developing a brand and logo with the latest trends in the blockchain industry. In the development process, our main focus was on creating a distinctive visual identity, ensuring a modern representation for Blocklogica.",
    link: "https://blocklogica.io",
    image: projectsImgs.blocklogicaLogo,
    status: projStatus.finished,
    type: [projects.logo,projects.uiux],
    techDesc:
      "In this project we tried to use modern and updated technologies.",
    techs: [
      techs.figma,
      techs.photoshop,
      techs.illustrator,
    ],
    shortDesc: "Logo and Brand Design",
    hashtags: [projects.logo,projects.brandDesign],
  },
];

export const teamData: teamMemberType[] = [
  {
    id: "0",
    name: "Aly",
    role: "Front-End & Blockchain Integration Engineer",
    image: teamImgs.alyMohamadi,
    roleType: roles.develop,
    social: [
      {
        href: "https://www.linkedin.com/in/aly-mohamadi/",
        icon: <socialSVGs.Linkedin />,
      },
      { href: "https://github.com/dev-aly3n", icon: <socialSVGs.Github /> },
      { href: "https://t.me/aly3n", icon: <socialSVGs.Telegram /> },
      { href: "https://aly3n.dev", icon: <socialSVGs.Website /> },
    ],
  },
  // {
  //   id: "1",
  //   name: "Abolfazl Eslami",
  //   role: "Design System Engineer",
  //   image: teamImgs.abolfazlEslami,
  //   roleType: roles.design,
  //   social: [
  //     { href: "https://github.com/illustrayking", icon: <socialSVGs.Github /> },
  //     { href: "https://t.me/illustrayking", icon: <socialSVGs.Telegram /> },
  //     { href: "https://dribbble.com/spila-dev", icon: <socialSVGs.Dribbble /> },
  //     {
  //       href: "https://codepen.io/illustrayking",
  //       icon: <socialSVGs.Codepen />,
  //     },
  //   ],
  // },
  // {
  //   id: "2",
  //   name: "Hakan Abdali",
  //   role: "Front-End Engineer",
  //   image: teamImgs.hakanAbdali,
  //   roleType: roles.develop,
  //   social: [
  //     { href: "https://github.com/abdalicodes", icon: <socialSVGs.Github /> },
  //     { href: "https://t.me/hakan_abdali", icon: <socialSVGs.Telegram /> },
  //     { href: "https://abdali.dev", icon: <socialSVGs.Website /> },
  //   ],
  // },
  {
    id: "3",
    name: "Arian",
    role: "Front-End Engineer",
    image: teamImgs.arianHamdi,
    roleType: roles.develop,
    social: [
      {
        href: "https://www.linkedin.com/in/arian-hamdi/",
        icon: <socialSVGs.Linkedin />,
      },
      { href: "https://github.com/arianhamdi", icon: <socialSVGs.Github /> },
      { href: "https://t.me/arian_hamdi", icon: <socialSVGs.Telegram /> },
    ],
  },
  {
    id: "34568",
    name: "Mohrez",
    role: "Front-End Engineer",
    image: teamImgs.mohrez,
    roleType: roles.develop,
    social: [
      {
        href: "https://www.linkedin.com/in/mohrezalizadeh/",
        icon: <socialSVGs.Linkedin />,
      },
      { href: "https://github.com/mohrezal", icon: <socialSVGs.Github /> },
      { href: "https://t.me/MomrezAl", icon: <socialSVGs.Telegram /> },
    ],
  },
  {
    id: "34567878",
    name: "Soheil",
    role: "Fullstack Engineer",
    image: teamImgs.soheilVatanpoor,
    roleType: roles.develop,
    social: [
      {
        href: "https://www.linkedin.com/in/soheil-vatanpoor-a112a422a/",
        icon: <socialSVGs.Linkedin />,
      },
      { href: "https://github.com/soheilous", icon: <socialSVGs.Github /> },
      { href: "https://t.me/soheilous", icon: <socialSVGs.Telegram /> },
    ],
  },
  {
    id: "345676780878",
    name: "Sadi",
    role: "Product Designer",
    image: teamImgs.sadeghGhafari,
    roleType: roles.design,
    social: [
      {
        href: "https://www.linkedin.com/in/sadeghgh/",
        icon: <socialSVGs.Linkedin />,
      },
      {
        href: "https://dribbble.com/DesignbySadi",
        icon: <socialSVGs.Dribbble />,
      },
      { href: "https://t.me/sadi_gh", icon: <socialSVGs.Telegram /> },
    ],
  },
  // {
  //   id: "4",
  //   name: "Alireza Ghorbani",
  //   role: "Full stack developer",
  //   image: teamImgs.alirezaGhorbani,
  //   roleType: roles.develop,
  //   social: [
  //     {
  //       href: "https://www.linkedin.com/in/alireza-ghorbani-618939190/",
  //       icon: <socialSVGs.Linkedin />,
  //     },
  //     {
  //       href: "https://github.com/alirezaghorbani79",
  //       icon: <socialSVGs.Github />,
  //     },
  //     {
  //       href: "https://t.me/alirezaghorbani_79",
  //       icon: <socialSVGs.Telegram />,
  //     },
  //   ],
  // },
  // {
  //   id: "5",
  //   name: "Tohid Sadeghi",
  //   role: "Graphic Designer",
  //   image: teamImgs.tohidSadeghi,
  //   roleType: roles.design,
  //   social: [
  //     { href: "https://t.me/Tohidsadeghi", icon: <socialSVGs.Telegram /> },
  //     { href: "https://dribbble.com/spila-dev", icon: <socialSVGs.Dribbble /> },
  //     {
  //       href: "https://www.linkedin.com/in/tohidsadeghi/",
  //       icon: <socialSVGs.Linkedin />,
  //     },
  //   ],
  // },
  // {
  //   id: "6",
  //   name: "Hamidreza Moradi",
  //   role: "Software Engineer & Web3 Developer",
  //   image: teamImgs.hamidrezaMoradi,
  //   roleType: roles.develop,
  //   social: [
  //     { href: "https://t.me/newbateni", icon: <socialSVGs.Telegram /> },
  //     {
  //       href: "https://www.linkedin.com/in/flokibb",
  //       icon: <socialSVGs.Linkedin />,
  //     },
  //     {
  //       href: "https://github.com/FlokiBB",
  //       icon: <socialSVGs.Github />,
  //     },
  //   ],
  // },
  // {
  //   id: "7",
  //   name: "DeFi Mancer",
  //   role: "Product Manager & Web3 Architect",
  //   image: teamImgs.defiMancer,
  //   roleType: roles.product,
  //   social: [
  //     { href: "https://t.me/devspila", icon: <socialSVGs.Telegram /> },
  //     // {
  //     //   href: "https://www.linkedin.com/in/flokibb",
  //     //   icon: <socialSVGs.Linkedin />,
  //     // },
  //     {
  //       href: "https://wa.me/971501511295",
  //       icon: <socialSVGs.Whatsapp />,
  //     },
  //   ],
  // },
  // {
  //   id: "8",
  //   name: "Mehdi A.",
  //   role: "Product Manager & Web3 Consultant",
  //   image: teamImgs.mehdiAbdie,
  //   roleType: roles.product,
  //   social: [
  //     { href: "https://t.me/devspila", icon: <socialSVGs.Telegram /> },
  //     {
  //       href: "https://www.linkedin.com/in/mehdiag/",
  //       icon: <socialSVGs.Linkedin />,
  //     },
  //     {
  //       href: "https://wa.me/971501511295",
  //       icon: <socialSVGs.Whatsapp />,
  //     },
  //     {
  //       href: "https://twitter.com/Mehdiaeth",
  //       icon: <socialSVGs.Twitter />,
  //     },
  //   ],
  // },
  // {
  //   id: "9",
  //   name: "Aref Rasti",
  //   role: "Front-End Engineer",
  //   image: teamImgs.arefRasti,
  //   roleType: roles.develop,
  //   social: [
  //     { href: "https://t.me/devspila", icon: <socialSVGs.Telegram /> },
  //     {
  //       href: "https://wa.me/971501511295",
  //       icon: <socialSVGs.Whatsapp />,
  //     },
  //   ],
  // },
  // {
  //   id: "10",
  //   name: "Youssef Hany",
  //   role: "Fullstack Engineer",
  //   image: teamImgs.youssefHany,
  //   roleType: roles.develop,
  //   social: [
  //     { href: "https://t.me/devspila", icon: <socialSVGs.Telegram /> },
  //     {
  //       href: "https://www.linkedin.com/in/youssef-hany-128066166/",
  //       icon: <socialSVGs.Linkedin />,
  //     },
  //   ],
  // },
  // {
  //   id: "11",
  //   name: "Muhammad Khizar",
  //   role: "Fullstack Engineer",
  //   image: teamImgs.muhammadKhizar,
  //   roleType: roles.develop,
  //   social: [
  //     { href: "https://t.me/greathayat", icon: <socialSVGs.Telegram /> },
  //     {
  //       href: "https://www.linkedin.com/in/muhammad-khizar-hayat/",
  //       icon: <socialSVGs.Linkedin />,
  //     },
  //   ],
  // },
  // {
  //   id: "12",
  //   name: "Gandom Eidizade",
  //   role: "Graphic Designer",
  //   image: teamImgs.gandomEidizade,
  //   roleType: roles.design,
  //   social: [
  //     { href: "https://t.me/devspila", icon: <socialSVGs.Telegram /> },
  //     {
  //       href: "https://www.linkedin.com/in/gandom-eidizadeh-a01122209/",
  //       icon: <socialSVGs.Linkedin />,
  //     },
  //     { href: "https://dribbble.com/spila-dev", icon: <socialSVGs.Dribbble /> },
  //   ],
  // },
];

export const commentsData: commentType[] = [
  {
    name: "Amita bachan",
    role: "CEO at Carbon",
    image: teamImgs.alyMohamadi,
    desc: "It's not the first time I work with Spila. They are an experienced developer team, delivered a clean project with future-proof reusable code. Recommended for a wide range of tasks, from backend to frontend and web3/blockchain.",
    link: "https://www.upwork.com/agencies/spila/modal-work-history?pageTitle=front-end%20development%20for%20web%203.0%20consultancy%20startup&_navType=modal&_modalInfo=%5B%7B%22navType%22%3A%22modal%22,%22title%22%3A%22front-end%20development%20for%20web%203.0%20consultancy%20startup%22,%22modalId%22%3A%221699776260055%22,%22channelName%22%3A%22modal-work-history%22%7D%5D",
  },
  {
    name: "Amita bachan",
    role: "CEO at Carbon",
    image: teamImgs.alyMohamadi,
    desc: "Prompt and effective, delivered as expected. Commended",
    link: "https://www.upwork.com/agencies/spila/modal-work-history?pageTitle=Design%20a%20captivating%20logo%20for%20a%20new%20web3%20company&_navType=modal&_modalInfo=%5B%7B%22navType%22%3A%22modal%22,%22title%22%3A%22Design%20a%20captivating%20logo%20for%20a%20new%20web3%20company%22,%22modalId%22%3A%221699776452371%22,%22channelName%22%3A%22modal-work-history%22%7D%5D",
  },
  {
    name: "Amita bachan",
    role: "CEO at Carbon",
    image: teamImgs.alyMohamadi,
    desc: "The team is professional and it is easy to communicate with them. I had a couple of changes in the scope of the task, which was handled very well and the frontend developers were very flexible with the changes. Availability was a bit of a challenge for us because of the time difference but we managed it at the end. I highly recommend this team.",
    link: "https://www.upwork.com/agencies/spila/modal-work-history?pageTitle=Next.js%20Developer%20for%20Contract%20Integration%20in%20a%20DEX&_navType=modal&_modalInfo=%5B%7B%22navType%22%3A%22modal%22,%22title%22%3A%22Next.js%20Developer%20for%20Contract%20Integration%20in%20a%20DEX%22,%22modalId%22%3A%221699776333877%22,%22channelName%22%3A%22modal-work-history%22%7D%5D",
  },
  {
    name: "Amita bachan",
    role: "CEO at Carbon",
    image: teamImgs.alyMohamadi,
    desc: "Spila's team was exceptional - efficient, communicative, and delivered high-quality work on time. I highly recommend them for any tech-heavy or design-related project.",
    link: "https://www.upwork.com/agencies/spila/modal-work-history?pageTitle=UI%2FUX%20design%20for%20a%20DeFi%20platform&_navType=modal&_modalInfo=%5B%7B%22navType%22%3A%22modal%22,%22title%22%3A%22UI%2FUX%20design%20for%20a%20DeFi%20platform%22,%22modalId%22%3A%221699776365189%22,%22channelName%22%3A%22modal-work-history%22%7D%5D",
  },
  {
    name: "Amita bachan",
    role: "CEO at Carbon",
    image: teamImgs.alyMohamadi,
    desc: "A+ work and delivered within the agreed schedule, recommended",
    link: "https://www.upwork.com/agencies/spila/modal-work-history?pageTitle=Figma%20design%20for%20a%20frontend&_navType=modal&_modalInfo=%5B%7B%22navType%22%3A%22modal%22,%22title%22%3A%22Figma%20design%20for%20a%20frontend%22,%22modalId%22%3A%221699776391572%22,%22channelName%22%3A%22modal-work-history%22%7D%5D",
  },
  {
    name: "Amita bachan",
    role: "CEO at Carbon",
    image: teamImgs.alyMohamadi,
    desc: "It was my second time working with the Spila team and once again, they delivered exactly what I had in mind, with attention to details and keen to do multiple revisions upon request. I highly recommend them to anyone looking for a creative and professional web agency.",
    link: "https://www.upwork.com/agencies/spila/modal-work-history?pageTitle=Need%20a%20eye-catching%20landing%20page%20for%20a%20novel%20web3%20brand&_navType=modal&_modalInfo=%5B%7B%22navType%22%3A%22modal%22,%22title%22%3A%22Need%20a%20eye-catching%20landing%20page%20for%20a%20novel%20web3%20brand%22,%22modalId%22%3A%221699776420523%22,%22channelName%22%3A%22modal-work-history%22%7D%5D",
  },
];

export const homePageMetaData: metaDataType = {
  siteTitle: "Spila",
  pageTitle: "Home",
  desc: "Spila is an application pipeline. Where the ideas become plans and plans becomes excellent product in minimum time and maximum efficiency.",
  keywords:
    "Spila , frontend , backend , react , javascript , nextjs , website , design , logo , application , spila developers , pipeline , application builder",
  imageUrl: "/cover.jpg",
  homePageUrl: "https://spila.dev",
  pageUrl: "https://spila.dev",
};

export const contactUsData: socialType[] = [
  {
    href: "https://www.linkedin.com/company/spiladev/",
    icon: <socialSVGs.Linkedin />,
  },
  { href: "https://t.me/devspila", icon: <socialSVGs.Telegram /> },
  { href: "https://github.com/spila-dev", icon: <socialSVGs.Github /> },
  { href: "https://dribbble.com/spila-dev", icon: <socialSVGs.Dribbble /> },
];
